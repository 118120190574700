import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Long: number;
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmEmailChange: Scalars['Boolean'];
  createRegistration: Registration;
};


export type MutationConfirmEmailChangeArgs = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateRegistrationArgs = {
  comments?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type Policy = {
  __typename?: 'Policy';
  content: Scalars['String'];
  created: Scalars['Date'];
  id: Scalars['Long'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  findPublicPolicies: Array<Policy>;
  loadEmailChange: Scalars['String'];
  systemAnnouncement?: Maybe<SystemAnnouncement>;
};


export type QueryLoadEmailChangeArgs = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};

export type Registration = {
  __typename?: 'Registration';
  approved: Scalars['Int'];
  approved_by?: Maybe<Scalars['String']>;
  approved_date?: Maybe<Scalars['Date']>;
  code?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  created: Scalars['Date'];
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  comments?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type SystemAnnouncement = {
  __typename?: 'SystemAnnouncement';
  message: Scalars['String'];
  type: SystemAnnouncementType;
};

export enum SystemAnnouncementType {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type FindPublicPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindPublicPoliciesQuery = (
  { __typename?: 'Query' }
  & { findPublicPolicies: Array<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'id' | 'name' | 'content' | 'version' | 'created'>
  )> }
);

export type LoadEmailChangeQueryVariables = Exact<{
  userId: Scalars['Int'];
  token: Scalars['String'];
}>;


export type LoadEmailChangeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loadEmailChange'>
);

export type ConfirmEmailChangeMutationVariables = Exact<{
  userId: Scalars['Int'];
  token: Scalars['String'];
}>;


export type ConfirmEmailChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmEmailChange'>
);

export type SystemAnnouncementQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemAnnouncementQuery = (
  { __typename?: 'Query' }
  & { systemAnnouncement?: Maybe<(
    { __typename?: 'SystemAnnouncement' }
    & Pick<SystemAnnouncement, 'message' | 'type'>
  )> }
);


export const FindPublicPoliciesDocument = gql`
    query findPublicPolicies {
  findPublicPolicies {
    id
    name
    content
    version
    created
  }
}
    `;
export const LoadEmailChangeDocument = gql`
    query loadEmailChange($userId: Int!, $token: String!) {
  loadEmailChange(userId: $userId, token: $token)
}
    `;
export const ConfirmEmailChangeDocument = gql`
    mutation confirmEmailChange($userId: Int!, $token: String!) {
  confirmEmailChange(userId: $userId, token: $token)
}
    `;
export const SystemAnnouncementDocument = gql`
    query systemAnnouncement {
  systemAnnouncement {
    message
    type
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    findPublicPolicies(variables?: FindPublicPoliciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindPublicPoliciesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindPublicPoliciesQuery>(FindPublicPoliciesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findPublicPolicies', 'query');
    },
    loadEmailChange(variables: LoadEmailChangeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoadEmailChangeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoadEmailChangeQuery>(LoadEmailChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'loadEmailChange', 'query');
    },
    confirmEmailChange(variables: ConfirmEmailChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ConfirmEmailChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ConfirmEmailChangeMutation>(ConfirmEmailChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'confirmEmailChange', 'mutation');
    },
    systemAnnouncement(variables?: SystemAnnouncementQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SystemAnnouncementQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SystemAnnouncementQuery>(SystemAnnouncementDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'systemAnnouncement', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;